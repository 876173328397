import type { ReactElement } from 'react';
import React, { useContext, createContext, useMemo, useState } from 'react';

const context = createContext<{ hiddenAlerts: string[]; hideAlert: (alertId: string) => void }>({
  hiddenAlerts: [],
  hideAlert: (alertId: string) => undefined,
});

export function AlertProvider({ children }: { children: ReactElement }) {
  const [hiddenAlerts, setHiddenAlerts] = useState<string[]>([]);

  const handleHideAlert = (alertId: string) => {
    setHiddenAlerts((alertIds) => [...alertIds, alertId]);
  };

  const value = useMemo(
    () => ({
      hiddenAlerts,
      hideAlert: (alertId: string) => handleHideAlert(alertId),
    }),
    [hiddenAlerts]
  );

  return <context.Provider value={value}>{children}</context.Provider>;
}

export const useAlertContext = () => useContext(context);
