export function event(eventName, payload = {}) {
  try {
    window.dataLayer.push({ event: eventName, ...payload });
  } catch (err) {
    console.error('Something went wrong with GTM data layer: ', err);
  }
}

export function logGiftPurchase(order) {
  event('', { ecommerce: null });
  event('purchase', {
    ecommerce: {
      purchase: {
        actionField: {
          id: order.order_id,
          revenue: order.amount,
          shipping: 0,
          coupon: '',
        },
        products: [
          {
            name: 'Подарочный сертификат',
            id: 'gift_certificate',
            price: order.amount,
            brand: 'Periodica',
            category: 'certificate',
            quantity: 1,
          },
        ],
      },
    },
  });
}

export function logDesignPurchase(pageCount) {
  let orderPrice;

  switch (pageCount) {
    case 40:
      orderPrice = 790;
      break;

    case 80:
    case 100:
      orderPrice = 990;
      break;

    case 160:
      orderPrice = 1190;
      break;

    default:
      orderPrice = 790;
      break;
  }

  event('', { ecommerce: null });
  event('purchase', {
    ecommerce: {
      purchase: {
        actionField: {
          revenue: orderPrice,
          shipping: 0,
          coupon: '',
        },
        products: [
          {
            name: `Услуга дизайна (${pageCount} стр.)`,
            id: `design_${pageCount}`,
            price: orderPrice,
            brand: 'Periodica',
            category: 'design',
            quantity: 1,
          },
        ],
      },
    },
  });
}
