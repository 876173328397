exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-claim-tsx": () => import("./../../../src/pages/claim.tsx" /* webpackChunkName: "component---src-pages-claim-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-delivery-index-tsx": () => import("./../../../src/pages/delivery/index.tsx" /* webpackChunkName: "component---src-pages-delivery-index-tsx" */),
  "component---src-pages-design-photobook-tsx": () => import("./../../../src/pages/design-photobook.tsx" /* webpackChunkName: "component---src-pages-design-photobook-tsx" */),
  "component---src-pages-faq-index-tsx": () => import("./../../../src/pages/faq/index.tsx" /* webpackChunkName: "component---src-pages-faq-index-tsx" */),
  "component---src-pages-help-how-to-js": () => import("./../../../src/pages/help/how-to.js" /* webpackChunkName: "component---src-pages-help-how-to-js" */),
  "component---src-pages-help-index-js": () => import("./../../../src/pages/help/index.js" /* webpackChunkName: "component---src-pages-help-index-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-order-approved-js": () => import("./../../../src/pages/order/approved.js" /* webpackChunkName: "component---src-pages-order-approved-js" */),
  "component---src-pages-order-canceled-js": () => import("./../../../src/pages/order/canceled.js" /* webpackChunkName: "component---src-pages-order-canceled-js" */),
  "component---src-pages-order-error-js": () => import("./../../../src/pages/order/error.js" /* webpackChunkName: "component---src-pages-order-error-js" */),
  "component---src-pages-order-pending-js": () => import("./../../../src/pages/order/pending.js" /* webpackChunkName: "component---src-pages-order-pending-js" */),
  "component---src-pages-order-success-js": () => import("./../../../src/pages/order/success.js" /* webpackChunkName: "component---src-pages-order-success-js" */),
  "component---src-pages-products-calendars-tsx": () => import("./../../../src/pages/products/calendars.tsx" /* webpackChunkName: "component---src-pages-products-calendars-tsx" */),
  "component---src-pages-products-gift-js": () => import("./../../../src/pages/products/gift.js" /* webpackChunkName: "component---src-pages-products-gift-js" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-products-notebooks-tsx": () => import("./../../../src/pages/products/notebooks.tsx" /* webpackChunkName: "component---src-pages-products-notebooks-tsx" */),
  "component---src-pages-products-photobooks-js": () => import("./../../../src/pages/products/photobooks.js" /* webpackChunkName: "component---src-pages-products-photobooks-js" */),
  "component---src-pages-products-postcards-tsx": () => import("./../../../src/pages/products/postcards.tsx" /* webpackChunkName: "component---src-pages-products-postcards-tsx" */),
  "component---src-pages-products-preorder-tsx": () => import("./../../../src/pages/products/preorder.tsx" /* webpackChunkName: "component---src-pages-products-preorder-tsx" */),
  "component---src-pages-products-prints-tsx": () => import("./../../../src/pages/products/prints.tsx" /* webpackChunkName: "component---src-pages-products-prints-tsx" */),
  "component---src-pages-products-wrapping-paper-tsx": () => import("./../../../src/pages/products/wrapping-paper.tsx" /* webpackChunkName: "component---src-pages-products-wrapping-paper-tsx" */),
  "component---src-pages-quality-js": () => import("./../../../src/pages/quality.js" /* webpackChunkName: "component---src-pages-quality-js" */),
  "component---src-pages-review-js": () => import("./../../../src/pages/review.js" /* webpackChunkName: "component---src-pages-review-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-tilda-page-js": () => import("./../../../src/templates/tilda-page.js" /* webpackChunkName: "component---src-templates-tilda-page-js" */)
}

